<template>
  <section id="ModeloLean" class="ModeloLean" data-aos="fade-down">
    <img
      class="w-100 img-fluid background-image d-none d-lg-block"
      src="@/assets/img/fondo_lean.webp"
      alt="First slide"
      loading="lazy"
    />
    <img
      class="w-100 img-fluid background-image d-block d-lg-none"
      src="@/assets/img/mobile-lean-background.webp"
      alt="First slide"
      loading="lazy"
    />

    <div class="py-5 my-5">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-12 col-lg-6 text-center text-lg-start px-5">
            <h2 class="mb-5 m-lg-3 fw-bold">{{ data.title }}</h2>
            <p class="mb-5 m-lg-0">
              {{ data.subtitle }}
            </p>
          </div>
          <div class="col-12 col-lg-6 text-center text-lg-start">
            <img
              class="img-banner"
              :src="require(`../../assets/img/${data.img}`)"
              alt="Imagen de seccion Modelo lean"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import { ConsultaSeccionBanner8x8 } from "@/helpers/contenido.js";
import store from '../../store';
export default {
  name: "ModeloLean",
  data() {
    return {
      data: {
        title: 'El modelo Wetforest 8x8 es de tipo Lean',
        subtitle: 'Este es el Framework que aplica a todos los programas ofrecidos.\n' +
          'Los programas son liderados por empresarios y ejecutivos con gran experiencia como strategic advisors.',
        img: "esquema_lean_esp.webp",
      },
    };
  },
  methods: {
    fetchData() {
        if (store.state.sections.modelLeanBanner) {
      this.data = {...store.state.sections.modelLeanBanner.components};}
    }
    
  },

  watch: {
    "$store.state.sections": function () {
      this.fetchData();
    },
  },

  mounted() {
    this.fetchData();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/* // Small devices (landscape phones, 576px and up)
 */

.ModeloLean {
  position: relative;
  margin-top: 5%;

  h2 {
    margin: 0 !important;
    font-size: 2.813rem !important;
  }

  p {
    font-size: 1.25rem !important;
    font-family: InterMedium !important;
  }
}

.background-image {
  position: absolute;
  z-index: -1;
  height: 100%;
}

.img-banner {
  width: 500px;
}

@media (max-width: 982px) {
  .background-image {
    opacity: 0.4;
  }

  .img-banner {
    width: 300px;
  }
}
</style>
